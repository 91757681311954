import styled from "styled-components";
import { device } from "../../device";

export const TextImageContainer = styled.div`
  color: #fff;
  height: auto;
  padding: 50px 0;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const TextImageWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;
`;
// padding: 0 24px;

export const TextImageRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1%;
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  align-content: center;

  @media ${device.laptop} {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col2 col1'` : `'col1 col2'`};
  }
`;
// export const TextImageRow = styled.div`
//   display: block;
//   width: 100%;
//   grid-gap: 1%;
//   align-items: center;
//   grid-template-areas: "col1 col1" "col2 col2";
//   align-content: center;

//   @media ${device.tablet} {
//     display: grid;
//     grid-template-columns: 1fr 2fr;
//     grid-template-areas: ${({ imgStart }) =>
//       imgStart ? `'col2 col1'` : `'col1 col2'`};
//   }
// `;

export const Column1 = styled.div`
  ${({ showDescription }) => {
    switch (showDescription) {
      case true:
        return `
      margin: auto;
      text-align: center;
      width: 90%;
      grid-area: col1;
      height: 100%;
      display: flex;
      flex-direction: column;
    
      @media ${device.tablet} {
        text-align: left;
      }
    
      @media ${device.laptop} {
        margin: auto;
      }
    `;
      default:
        return `
      margin: auto;
      text-align: center;
      width: 90%;
      grid-area: col1;
      height: 100%;
      
      @media ${device.tablet} {
        text-align: left;
      }
      
      @media ${device.laptop} {
        margin: auto;
      }
    `;
    }
  }}
`;

export const Column2 = styled.div`
  margin: auto;
  text-align: center;
  width: 90%;
  grid-area: col2;
  height: 100%;

  @media ${device.laptopL} {
    width: 100%;
  }
`;

export const HeadingWrapper = styled.div`
  ${({ showDescription }) => {
    switch (showDescription) {
      case true:
        return `
      width: 90%;
      height: 50%;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom: 2rem;
      display: flex;
      
      @media ${device.laptopL} {
        padding-bottom: 0;
      }
    `;
      default:
        return `
      width: 90%;
      height: 100%;
      margin: auto;
      padding-top: 0;
      padding-bottom: 60px;
      display: flex;
      
      @media ${device.laptopL} {
        padding-bottom: 0;
      }
    `;
    }
  }}
`;

export const Heading = styled.h1`
  ${({ showDescription, textColor }) => {
    switch (showDescription) {
      case true:
        return `
      margin: auto;
      font-size: 2rem;
      line-height: 1.1;
      font-weight: 600;
      color: ${textColor};
      text-align: center;
      
      @media ${device.mobileL} {
        font-size: 2.5rem;
      }
      
      @media ${device.laptop} {
        font-size: 2.5rem;
        text-align: left;
      }

      @media ${device.laptopM} {
        font-size: 3rem;
      }
    `;
      default:
        return `
      margin: auto;
      font-size: 2rem;
      line-height: 1.1;
      font-weight: 600;
      color: ${textColor};
      text-align: center;
      
      @media ${device.mobileL} {
        font-size: 2.5rem;
      }
      
      @media ${device.laptop} {
        font-size: 3rem;
        text-align: left;
      }
    `;
    }
  }}
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
`;

export const Image = styled.img`
  width: 100%;
  margin: auto;
  padding-right: 0;
  height: auto;
  border-radius: 8%;

  @media ${device.laptop} {
    margin: auto;
  }
`;

export const ImgCont = styled.div`
  height: 100%;
  width: 100%;
  background-image: ${(props) =>
    `url(${require(`../../images/${props.imageFile}`)})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const DescriptionWrapper = styled.div`
  ${({ showDescription }) => {
    switch (showDescription) {
      case true:
        return `
          width: 90%;
          height: 50%;
          margin: 0 auto;
          padding-top: 0;
          padding-bottom: 2.5rem;
          display: flex;
    
          @media ${device.mobileS} {
            padding-bottom: 1.5rem;
          }

          @media ${device.laptop} {
            padding-bottom: 0;
          }
        `;
      default:
        return `
          display: none;
        `;
    }
  }}
`;

export const Description = styled.p`
  ${({ showDescription, textColor }) => {
    switch (showDescription) {
      case true:
        return `
          margin: auto;
          font-size: 1.125rem;
          line-height: 1.9rem;
          color: ${textColor};
          text-align: center;
      
          @media ${device.laptop} {
            text-align: left;
            font-size: 1.25rem;
          }

        `;
      default:
        return `
          display: none;
        `;
    }
  }}
`;
