import React from "react";
import { SEO } from "../components/SEO";
import HoldingBlock from "../components/HoldingBlock";
import { holdingPageData } from "../components/HoldingBlock/data";
import lavenderLogo from "../images/FNEP_Icon_Lavender.png";

const HoldingPage = () => {
  return (
    <>
      <SEO
        title="First Nations Equity Partners"
        description="Generating positive real-world outcomes."
        type="webapp"
        name="First Nations Equity Partners"
      />
      <HoldingBlock {...holdingPageData} logoFile={lavenderLogo} />
    </>
  );
};

export default HoldingPage;
