import styled, { keyframes } from "styled-components";
import { device } from "../../device";

export const InsightsBlockContainer = styled.div`
  color: #fff;
  height: auto;
  padding: 50px 0;
  display: flex;
  align-items: center;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    padding: 3rem 0;
  }
`;

export const InsightsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 1rem;
  width: 90%;
  max-width: 1350px;
  margin: auto;
  height: 90%;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const InsightWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
`;

export const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: ${(props) =>
    `url(${require(`../../images/${props.imageFile}`)})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: ${(props) => props.filter};
  transition: transform 0.8s ease;
  overflow: hidden;
`;

export const InsightContainer = styled.a`
  border: 0.5px solid #7d7d7d;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  min-height: 400px;
  max-height: 600px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &:hover ${ImgWrapper} {
    transform: scale(1.1);
  }
`;

export const CategoryWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  border: 2px solid #f8f0e3;
  border-radius: 5px;
`;

export const Category = styled.p`
  color: #f8f0e3;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0.1rem;
`;

// max-height: 300px;
export const InfoContainer = styled.div`
  height: 100%;
  width: 90%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
`;
//   grid-template-rows: 1fr 2fr 2fr;
//   grid-template-columns: 1fr;

export const DateWrapper = styled.div`
  height: 10%;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Date = styled.p`
  color: #7d7d7d;
  margin: 0;
  font-size: 0.85rem;
`;

export const HeadingWrapper = styled.div`
  max-height: 45%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const Heading = styled.h3`
  height: 100%;
  width: 100%;
  font-size: 1.15rem;
  line-height: 1.1;
  color: #283039;
  margin: 0;

  @media ${device.mobileL} {
    font-size: 1.5rem;
  }
`;
// white-space: nowrap;
// overflow: hidden;
// text-overflow: ellipsis;

export const DescriptionWrapper = styled.div`
  max-height: 45%;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  height: 100%;
  max-height: 9rem;
  width: 100%;
  color: #7d7d7d;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;
//   white-space: nowrap;

// export const InfoContainer = styled.div`
//   height: 100%;
//   max-height: 300px;
//   width: 90%;
//   background-color: #ffffff;
//   display: grid;
//   grid-template-rows: 1fr 2fr 2fr;
//   grid-template-columns: 1fr;
//   margin: 0 auto;
//   overflow: hidden;
// `;

// export const DateWrapper = styled.div`
//   height: 100%;
//   width: 100%;
//   min-width: 0;
// `;

// export const Date = styled.p`
//   color: #7d7d7d;
//   margin-bottom: 0;
//   font-size: 0.8rem;
// `;

// export const HeadingWrapper = styled.div`
//   height: 100%;
//   width: 100%;
//   min-width: 0;
// `;

// export const Heading = styled.h2`
//   width: 100%;
//   color: #283039;
//   margin-top: 1rem;
//   margin-bottom: 1rem;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

// export const DescriptionWrapper = styled.div`
//   height: 100%;
//   max-height: 120px;
//   width: 100%;
//   min-width: 0;
// `;

// export const Description = styled.p`
//   color: #7d7d7d;
//   margin-top: 0;
//   white-space: normal;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;
