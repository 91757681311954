import styled from "styled-components";
import { device } from "../../device";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

export const AccordionContainer = styled.div`
  padding: 3rem 0 4rem 0;
  height: auto;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 0 0 2rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  min-height: 700px;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
`;

export const HeadingContainer = styled.div`
  width: 90%;
`;

export const Heading = styled.h1`
  text-align: center;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const DescriptionContainer = styled.div`
  width: 90%;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.textColor};
`;

export const PanelContainer = styled.div`
  width: 65%;
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
`;

export const PanelTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0;
  cursor: pointer;
  transition: 0.4s;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.textColor};
  border-bottom-width: thin;
  text-align: left;
`;

export const PanelTitle = styled.h3`
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  width: 90%;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }
`;

export const PanelIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10%;
  text-align: center;
`;

export const PanelIcon = styled.a`
  font-size: 1.5rem;
`;

export const PanelDescriptionContainer = styled.div`
  @keyframes slideDown {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  overflow: hidden;
  animation-name: slideDown;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
`;

// export const PanelDescriptionContainer = styled.div`
//   overflow: hidden;
//   opacity: 0;
//   transition: opacity 0.7s;

//   &.active {
//     opacity: 1;
//   }
// `;

export const PanelDescription = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.textColor};
`;

export const StyledChevDown = styled(FaChevronDown)`
  @keyframes rotateDown {
    0% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  color: ${(props) => props.textcolor};
  animation-name: rotateDown;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
`;

export const StyledChevRight = styled(FaChevronRight)`
  @keyframes rotateUp {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  color: ${(props) => props.textcolor};
  animation-name: rotateUp;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
`;
