import styled from "styled-components";
import { device } from "../../device";

export const ThreeIconContainer = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#DFDCCF" : "#283039")};
  height: auto;
  padding: 50px 0;

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0 0;
  }
`;
// height: 700px;
// @media ${device.tablet} {
//   font-size: 40px;
// }

// export const ThreeIconContainer = styled.div`
//   background: ${({ lightBg }) => (lightBg ? "#DFDCCF" : "#283039")};
//   height: 700px;

//   @media screen and (max-width: 960px) {
//     height: auto;
//     padding-top: 2rem;
//     padding-bottom: 3rem;
//   }

//   @media screen and (max-width: 768px) {
//     padding: 50px 0;
//   }
// `;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;

  @media ${device.laptop} {
  }
`;
// Change height to 90% from 100% to try and fit the button into the page

export const HeadingContainer = styled.div``;

export const Heading = styled.h1`
  text-align: center;
  font-size: 32px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#FFFFFF" : "#283039")};

  @media ${device.mobileL} {
    font-size: 48px;
  }
`;

export const DescriptionContainer = styled.div``;

export const Description = styled.p`
  text-align: center;
  font-size: 28px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#FFFFFF" : "#283039")};
`;

export const ThreeIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div``;

export const IconImage = styled.a`
  font-size: 3rem;
  margin-bottom: 0;

  @media ${device.laptop} {
    font-size: 4rem;
    margin-bottom: 10px;
  }
`;

export const IconTitleContainer = styled.div``;

export const IconTitle = styled.h3`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#FFFFFF" : "#283039")};

  @media ${device.laptop} {
    margin-bottom: 24px;
    font-size: 28px;
  }
`;

export const IconDescriptionContainer = styled.div``;

export const IconDescription = styled.p`
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 24px;
  color: ${({ lightText }) => (lightText ? "#FFFFFF" : "#283039")};

  @media ${device.laptop} {
    margin-bottom: 35px;
    font-size: 18px;
  }
`;
