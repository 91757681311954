import styled, { keyframes } from "styled-components";
import { device } from "../../device";
import { BsArrowRight } from "react-icons/bs";

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  @media ${device.laptop} {
    padding: 3rem 0 6rem;
  }
`;
// height: calc(100vh - 80px);

// To reset, change to below
// padding: 3rem 0; laptop version
// remove height from laptop version

export const TeamBlockWrapper = styled.div`
  height: 80%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const HeadingWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0 24px;
`;

export const Heading = styled.h1`
  text-align: center;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  color: #283039;

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const SubheadingWrapper = styled.div`
  margin: auto;
  width: 80%;

  @media ${device.desktop} {
    max-height: 150px;
  }
`;

export const Subheading = styled.p`
  max-width: 1350px;
  margin: 0.5rem auto;
  text-align: center;
  color: #283039;
  font-size: 1.25rem;
  line-height: 1.5;

  @media ${device.laptop} {
    max-width: 1072px;
  }
`;

export const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  width: 90%;
  max-width: 524px;
  margin: 0 auto;
  padding: 1.5rem;

  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 798px;
  }
`;
// ADD BELOW LINES IF WANTING TO CHANGE TO ROWS OF 4
// @media ${device.laptop} {
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   max-width: 1072px;
// }

export const StaffContainer = styled.div`
  display: flex;
  background-image: ${(props) =>
    `url(${require(`../../images/${props.image}`)})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5%;
  max-width: 250px;
  width: 100%;
  height: 250px;
  margin: auto;
  backdrop-filter: blur(0);
  overflow: hidden;
`;
/*IF REMOVE border-radius: 5% then also remove backdrop-filter: blur(0) & overflow: hidden*/

export const StaffName = styled.h3`
  color: #ffffff;
  width: 90%;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.1;
  opacity: 0;
  transition: opacity 0.5s;

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }

  @media ${device.laptop} {
  }
`;

export const StaffTitle = styled.p`
  color: #7d7d7d;
  width: 90%;
  margin: 0.1rem auto 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  opacity: 0;
  transition: opacity 0.5s;

  @media ${device.mobileL} {
    font-size: 1.4rem;
  }

  @media ${device.laptop} {
  }
`;

export const ReadBio = styled.p`
  color: #ffffff;
  width: 90%;
  margin: 0.5rem auto 0.5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  opacity: 0;
  transition: opacity 0.5s;

  @media ${device.laptop} {
  }
`;

export const RightArrow = styled(BsArrowRight)`
  @keyframes movementAnimation {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  color: #ffffff;
  vertical-align: middle;
  animation-name: movementAnimation;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const StaffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  transition: backdrop-filter 0.5s;

  &:hover {
    backdrop-filter: brightness(25%);
    cursor: pointer;
  }

  &:hover ${StaffName} {
    opacity: 1;
  }

  &:hover ${StaffTitle} {
    opacity: 1;
  }

  &:hover ${ReadBio} {
    opacity: 1;
  }
`;
