import styled from "styled-components";
import { device } from "../../device";

export const PageBreakBlockContainer = styled.div`
  height: auto;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
    height: ${({ blockHeight }) =>
      blockHeight === "small" ? "50vh" : "calc(100vh - 80px)"};
    padding: 0;
  }
`;
// calc(100vh - 80px);
//        filter: ${backgroundFilter};
//padding: 50px 0;
//padding: 4rem 0;

export const PageBreakBlockWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  min-height: 350px;
  width: 100%;
  backdrop-filter: ${(props) => props.backgroundFilter};
`;

export const TextContainer = styled.div`
  height: 50%;
  width: 80%;
  max-width: 1350px;
  margin: auto;
`;
// @media ${device.laptop} {
//   width: 50%;
// }

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

export const QuoteText = styled.p`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.1;
  color: ${(props) => props.textColor};
  margin-bottom: 0;
  font-style: italic;

  @media ${device.tablet} {
    font-size: 1.8rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
  }
`;

export const QuoteAuthor = styled.p`
  width: 100%;
  font-size: 1.1rem;
  line-height: 1.1;
  color: ${(props) => props.textColor};
  margin-top: 1rem;

  @media ${device.tablet} {
    font-size: 1.25rem;
  }
`;
