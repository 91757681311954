import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ApproachPage from "./pages/ApproachPage";
import AboutPage from "./pages/AboutPage";
import InsightsPage from "./pages/InsightsPage";
import ContactPage from "./pages/ContactPage";
import CommunityPage from "./pages/CommunityPage";
import InvestorsPage from "./pages/InvestorsPage";
import CompaniesPage from "./pages/CompaniesPage";
import NotFoundPage from "./pages/NotFoundPage";
import HoldingPage from "./pages/HoldingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { navBarData } from "./components/Navbar/data";

function App() {
  return (
    <Router>
      {/* <Navbar {...navBarData} /> */}
      <Routes>
        {/* <Route path="/" exact element={<HomePage id="HomePage" />} /> */}
        <Route path="/" exact element={<HoldingPage id="HoldingPage" />} />
        {/* <Route
          path="/our-approach"
          exact
          element={<ApproachPage id="OurApproachPage" />}
        />
        <Route path="/about" exact element={<AboutPage id="AboutPage" />} />
        <Route
          path="/insights"
          exact
          element={<InsightsPage id="InsightsPage" />}
        />
        <Route
          path="/contact"
          exact
          element={<ContactPage id="ContactPage" />}
        />
        <Route
          path="/who's-involved/community"
          exact
          element={<CommunityPage id="CommunityPage" />}
        />
        <Route
          path="/who's-involved/investors"
          exact
          element={<InvestorsPage id="InvestorsPage" />}
        />
        <Route
          path="/who's-involved/companies"
          exact
          element={<CompaniesPage id="CompaniesPage" />}
        />
        <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
