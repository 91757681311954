import styled, { keyframes } from "styled-components";
import { device } from "../../device";

export const ValuesBlockContainer = styled.div`
  height: auto;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }
`;

export const MultiColumnWrapper = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const HeadingWrapper = styled.div`
  width: 80%;
  margin: auto;

  @media ${device.laptop} {
    margin: 0 auto;
  }

  @media ${device.laptopM} {
    margin: auto;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  margin: auto;

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const SubHeadingWrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;

  @media ${device.laptop} {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
`;

export const SubHeading = styled.p`
  width: 90%;
  max-width: 1350px;
  margin: 0.5rem auto;
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

export const MultiColumnGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  width: 90%;
  max-width: 1350px;
  margin: auto;
  max-height: 80%;
  padding-top: 2rem;
  row-gap: 1rem;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
  }
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: 70px 25px 1fr;
  height: 100%;
  max-height: 550px;
  width: 100%;
  column-gap: 1rem;
  justify-items: center;

  @media ${device.mobileL} {
    grid-template-rows: 70px 35px 1fr;
  }

  @media only screen and (max-width: 428px) and (min-width: 425px) {
    grid-template-rows: 70px 65px 1fr;
  }

  @media ${device.tablet} {
    grid-template-rows: 70px 45px 1fr;
  }

  @media ${device.laptopL} {
    grid-template-rows: 70px 45px 1fr;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0;
`;

export const Icon = styled.div`
  right: auto;
  font-size: 2.5rem;
  margin: auto auto 0;
  color: ${(props) => props.textColor};

  @media ${device.laptop} {
    font-size: 3.5rem;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.h1`
  width: 90%;
  max-width: 1350px;
  margin: auto auto 0;
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 1.25rem;
  line-height: 1.1;

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }
`;

export const DescriptionWrapper = styled.div`
  width: 100%;

  @media ${device.tablet} {
    padding-top: 0.5rem;
  }

  @media ${device.laptop} {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
`;

export const Description = styled.p`
  width: 90%;
  max-width: 1350px;
  margin: 0.5rem auto;
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 1.125rem;
  line-height: 1.5rem;

  &:nth-child(2) {
    padding-top: 0.8rem;
  }
`;
