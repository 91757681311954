import styled from "styled-components";
import { device } from "../../device";

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;

  @media ${device.laptop} {
    height: calc(100vh - 80px);
  }
`;

export const HeadingWrapper = styled.div`
  width: 90%;
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#FFFFFF" : "#283039")};

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-items: center;
  text-align: center;
  width: 90%;
  height: 80%;
  margin: auto;
`;

export const Image = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: auto;
`;
// filter: grayscale(100%);
