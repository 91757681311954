import styled, { keyframes } from "styled-components";
import { device } from "../../device";

export const FivePillarsGridBlockContainer = styled.div`
  color: #fff;
  height: auto;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 2rem 0 4rem;
  }
`;
// @media ${device.laptop} {
//   height: calc(100vh - 80px);
//   padding: 0;
// }

export const FivePillarsGridBlockWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingWrapper = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 1rem auto 0.5rem;
`;

export const Heading = styled.h1`
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const DescriptionWrapper = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 0.5rem auto 1.5rem;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.textColor};
`;

export const FivePillarsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  width: 90%;
  max-width: 1350px;
  margin: auto;
  max-height: 70%;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
    padding-top: 2rem;
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(5, 1fr);
    row-gap: 0;
  }
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 55px 280px;
  height: 100%;
  width: 100%;
  column-gap: 1rem;
  justify-items: center;
  border-bottom: solid #283039 2px;

  &:first-of-type {
    border-top: solid #283039 2px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  @media ${device.mobileS} {
    grid-template-rows: 60px 55px 280px;
  }

  @media ${device.mobileM} {
    grid-template-rows: 55px 50px 240px;
  }

  @media ${device.mobileL} {
    grid-template-rows: 65px 70px 240px;
  }

  @media ${device.tablet} {
    grid-template-rows: 55px 130px 300px;
    border-left: solid #283039 2px;
    border-bottom: none;

    &:first-of-type {
      border-top: none;
      border-left: none;
    }

    &:nth-of-type(4) {
      border-left: none;
    }
  }

  @media ${device.laptop} {
    grid-template-rows: 65px 140px 340px;

    &:nth-of-type(4) {
      border-left: solid #283039 2px;
    }
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const Icon = styled.div`
  font-size: 2rem;
  margin: auto auto 0.3rem;
  color: ${(props) => props.iconColor};

  @media ${device.mobileL} {
    font-size: 2.5rem;
  }

  @media ${device.laptop} {
    font-size: 3rem;
    margin-bottom: 0;
  }
`;
// margin: auto auto 0;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Title = styled.h2`
  width: 90%;
  margin: 0 auto auto;
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 1.25rem;
  line-height: 1.1;

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }

  @media ${device.laptop} {
    font-size: 1.5rem;
  }

  @media ${device.laptopM} {
    font-size: 1.75rem;
  }
`;

export const SubheadingDescriptionWrapper = styled.div``;

export const SubheadingDescription = styled.p``;

export const SubheadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(4, 25%);
  grid-auto-rows: 1fr;
`;

export const SubheadingWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Subheading = styled.p`
  width: 90%;
  margin: 0.5rem auto;
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 1.125rem;
  line-height: 1.5rem;
`;
