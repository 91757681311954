import React, { useState, useEffect } from "react";
import {
  HoldingContainer,
  HeroBackground,
  HeroBackgroundLogo,
  LogoContainer,
  LogoImage,
  HeroContent,
  H1,
  P,
} from "./HoldingBlockElements";
import image from "../../images/FNEP_Primary-Logo__Orange+White.png";

const HoldingBlock = ({
  heading,
  paragraph,
  textColor,
  backgroundImage,
  backgroundImageFileSmall,
  backgroundImageFileMedium,
  backgroundImageFileLarge,
  backgroundImageFileXLarge,
  backgroundTinting,
  backgroundFilter,
  alt,
  backgroundColor,
  backgroundLogo,
  backgroundLogoActive,
  logoFile,
}) => {
  return (
    <>
      <HoldingContainer
        backgroundImage={backgroundImage}
        backgroundTinting={backgroundTinting}
        backgroundColor={backgroundColor}
        bgImageFileSmall={backgroundImageFileSmall}
        bgImageFileMedium={backgroundImageFileMedium}
        bgImageFileLarge={backgroundImageFileLarge}
        bgImageFileXLarge={backgroundImageFileXLarge}
      >
        <LogoContainer>
          <LogoImage src={image} alt={alt} />
        </LogoContainer>
        <HeroBackground backgroundFilter={backgroundFilter}>
          <HeroBackgroundLogo
            bgLogoActive={backgroundLogoActive}
            bgLogo={backgroundLogo}
            src={logoFile}
            alt={alt}
          />
        </HeroBackground>
        <HeroContent>
          <H1 textColor={textColor}>{heading}</H1>
          <P textColor={textColor}>{paragraph}</P>
        </HeroContent>
      </HoldingContainer>
    </>
  );
};

export default HoldingBlock;
