import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { device } from "../../device";

export const DropdownContainer = styled.div`
  @keyframes slideDown {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  display: flex;
  animation-name: ${({ toggle }) => (toggle ? "slideDown" : "slideUp")};
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  width: 150px;

  @media ${device.laptop} {
    position: absolute;
    top: 80px;
    width: 200px;
    background: #ffffff;
    border-radius: 0 0 5px 5px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: auto;
  width: 85%;
  padding: 0;
`;

// export const MenuContainer = styled.div`
//   text-align: start;
//   margin: auto;
//   width: 85%;
//   padding: 0;
// `;

export const MenuItemWrapper = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  margin: auto;
  align-items: space-between;
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  text-decoration: none;
  color: #283039;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 8px;
    background: #d37a51;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.active:after {
    transform: scaleX(1);
  }

  @media ${device.laptop} {
    font-size: 1.1rem;
    margin-left: 0;
  }
`;
