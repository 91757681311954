import styled from "styled-components";
import { device } from "../../device";
import { FaTimes } from "react-icons/fa";

export const BioContainer = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  display: flex;
  background: #ffffff;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 100;
  position: fixed;
  align-content: center;
  justify-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: ${({ unmount }) => (unmount ? "slideOut" : "slideIn")};
  animation-duration: 0.5s;
  animation-timing-function: ease;
`;

export const StyledTimes = styled(FaTimes)`
  position: absolute;
  color: #283039;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const BioWrapper = styled.div`
  height: 100%;
  width: 100%;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`;

export const BioDetailsWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;

  @media ${device.tablet} {
    max-height: 650px;
    max-width: 450px;
  }
`;
// @media ${device.desktop} {
//   max-height: 60%;
//   max-width: 60%;
// justify-content: center;
// display: flex;
// flex-direction: column;
// }

export const BioName = styled.h1`
  font-size: 2rem;
  line-height: 1.1;
  color: #283039;
  margin-top: 5rem;
  margin-bottom: 0;
  padding: 0 20px;

  @media ${device.mobileL} {
    font-size: 3rem;
  }

  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const BioTitle = styled.h3`
  font-size: 1.55rem;
  line-height: 1.1;
  color: #7d7d7d;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 20px;

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }
`;

export const BioDescription = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #283039;
  padding: 0 20px;
`;

export const BioImageWrapper = styled.div`
  visibility: hidden;

  @media ${device.tablet} {
    visibility: visible;
  }
`;

export const BioImage = styled.div`
  display: flex;
  background-image: ${(props) =>
    `url(${require(`../../images/${props.image}`)})`};
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 101;
  width: 100%;
  height: 100%;
`;
