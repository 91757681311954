import { GrGroup } from "react-icons/gr";
import { GiMicroscope, GiShakingHands } from "react-icons/gi";
import { BsPieChart } from "react-icons/bs";
import { IoIosConstruct } from "react-icons/io";
import { FaAward } from "react-icons/fa";
import { RiFocus2Line } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";

export const multiColumnKeyFeaturesData = {
  id: "keyFeatures",
  backgroundImage: false,
  backgroundImageFile: "",
  backgroundColor: "#DFDCCF",
  textColor: "#283039",
  iconColor: "#283039",
  heading: "Advantages that make a difference",
  description: "",
  columns: [
    {
      icon: <FaAward />,
      title: "Market leading",
      description:
        "The first socially-responsible investment fund focused on Australia's First Nations people.",
    },
    {
      icon: <VscWorkspaceTrusted />,
      title: "Proven expertise",
      description:
        "Our three founders have a long track record of engagement with corporate Australia and First Nations communities.",
    },
    {
      icon: <RiFocus2Line />,
      title: "Single strategy",
      description:
        "A targeted approach to ESG and sustainable investing without the complications of competing objectives.",
    },
    {
      icon: <GiMicroscope />,
      title: "Proprietary scoring",
      description:
        "Our proprietary framework assesses companies against five core principles to identify opportunities for measurable improvement.",
    },
    {
      icon: <GiShakingHands />,
      title: "Genuine engagement",
      description:
        "We engage with companies to stimulate their participation on issues crucial to generating positive outcomes for Indigenous Australians.",
    },
    {
      icon: <BsPieChart />,
      title: "Systematic implementation",
      description:
        "Our unique collaboration with Macquarie Asset Management leverages their existing processes to maximise returns and reduce risks.",
    },
  ],
};

export const multiColumnValuesData = {
  id: "ourValues",
  backgroundImage: false,
  backgroundImageFile: "",
  backgroundColor: "#FFFFFF",
  textColor: "#283039",
  iconColor: "#D37A51",
  heading: "Our values",
  description: "Our values guide what we do, why we exist, and who we hire.",
  columns: [
    {
      icon: <FaAward />,
      title: "Authenticity",
      description:
        "Having a voice and being heard is important to us. Having the courage to speak with honesty is vital. But true authenticity is more than just words. It also requires action. Action that is rooted in beliefs and demonstrates who we are.",
    },
    {
      icon: <VscWorkspaceTrusted />,
      title: "Openness",
      description:
        "We are open to dialogue with all our stakeholders. We welcome a conversation, even when it’s difficult. We respect that people have varying perspectives.",
    },
    {
      icon: <RiFocus2Line />,
      title: "Simplicity",
      description:
        "We are clear about our purpose, we are clear about our view of the future, and we have a simple roadmap to get there. We like to keep things straightforward, and we don’t need to overcomplicate the process. Working together, we can make progress and we can achieve real, tangible outcomes.",
    },
    {
      icon: <GiMicroscope />,
      title: "Equality",
      description:
        "We provide leadership for others to follow, yet we see ourselves as an equal partner on the journey. Not better, nor less, but a reliable teammate that provides guidance and inspiration.",
    },
  ],
};
