import styled from "styled-components";
import { device } from "../../device";

export const ContactContainer = styled.div`
  height: auto;
  padding: 50px 0;
  position: relative;
  z-index: 2;
  display: flex;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      `;
      default:
        return `
        background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  z-index: -1;
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  filter: ${(props) => props.logoBrightness};
`;

export const ContactWrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  max-width: 1350px;
`;

export const ContactGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  margin-right: auto;
  margin-left: 0;

  @media ${device.mobileM} {
    width: 85%;
  }

  @media ${device.mobileL} {
    width: 60%;
  }

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.laptop} {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
`;

export const Heading = styled.h3`
  text-align: left;
  color: ${(props) => props.textColor};
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  margin: auto auto 0 0;

  @media ${device.tablet} {
    font-size: 2rem;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
`;

export const Address = styled.p`
  text-align: left;
  color: ${(props) => props.textColor};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: auto auto auto 0;
`;

export const PhoneWrapper = styled.div`
  display: flex;
`;

export const Phone = styled.p`
  text-align: left;
  color: ${(props) => props.textColor};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: auto auto auto 0;
`;

export const EmailWrapper = styled.div`
  display: flex;
`;

export const Email = styled.p`
  text-align: left;
  color: ${(props) => props.textColor};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: auto auto auto 0;
`;
