import styled from "styled-components";
import { device } from "../../device";

export const HoldingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
  position: relative;
  z-index: 1;
  ${({
    backgroundImage,
    backgroundColor,
    bgImageFileSmall,
    bgImageFileMedium,
    bgImageFileLarge,
    bgImageFileXLarge,
  }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${bgImageFileSmall}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media ${device.laptop} {
          background-image: ${`url(${require(`../../images/${bgImageFileMedium}`)})`};
        }

        @media ${device.laptopL} {
          background-image: ${`url(${require(`../../images/${bgImageFileLarge}`)})`};
        }

        @media ${device.desktop} {
          background-image: ${`url(${require(`../../images/${bgImageFileXLarge}`)})`};
        }
      `;
      default:
        return `
        background: ${backgroundColor};
        `;
    }
  }}

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: liner-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export const HeroBackground = styled.div`
  display: flex;
  align-content: center;
  justify-content: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ backgroundFilter }) => {
    switch (backgroundFilter) {
      case "none":
        return ``;
      default:
        return `
        backdrop-filter: ${backgroundFilter};
        `;
    }
  }}
`;

export const HeroBackgroundLogo = styled.img`
  max-width: 50%;
  max-height: 100%;
  margin: auto 0;
  filter: brightness(70%);
`;

export const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
`;

export const LogoImage = styled.img`
  max-width: 300px;
  margin: auto;

  @media ${device.tablet} {
    margin-left: 0;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    width: 50%;
    padding: 8px 24px;
  }
`;

export const H1 = styled.h1`
  color: ${(props) => props.textColor};
  font-size: 2.5rem;
  text-align: left;
  width: 100%;
  max-width: 700px;

  @media ${device.tablet} {
    white-space: pre;
    width: 80%;
  }

  @media ${device.laptop} {
    font-size: 3.75rem;
  }
`;

export const P = styled.p`
  margin-top: 24px;
  color: ${(props) => props.textColor};
  font-size: 1.5rem;
  text-align: left;
  width: 100%;
  max-width: 700px;

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 80%;
  }

  @media ${device.laptop} {
    font-size: 1.875rem;
  }
`;
