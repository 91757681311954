import { GrGroup } from "react-icons/gr";
import { BsPieChart } from "react-icons/bs";
import { AiFillCustomerService } from "react-icons/ai";
import { FaAward, FaGhost } from "react-icons/fa";

export const fivePillarsGridData = {
  id: "fivePillars",
  backgroundImage: false,
  backgroundImageFile: "",
  backgroundColor: "#FFFFFF",
  pillarHeadingColor: "#D37A51",
  textColor: "#283039",
  heading: "Our assessment framework",
  description: [
    "We have developed a proprietary framework to assess ASX 200 companies against a range of activities across their business that lead to positive outcomes for First Nations people.",
    "Leveraging research into global racial equity standards, our framework is built on five pillars. The activities that support each pillar reflect actions that companies can take to support and generate positive outcomes for First Nations communities. This first-of-its-kind framework allows us to assess the actual and potential contribution of companies to First Nations causes and communities.",
    "The assessments are initially determined and substantiated using publicly available data, and are updated as new information becomes available.",
  ],
  columns: [
    {
      icon: <FaGhost />,
      title: "Representation & voice in leadership",
      description: "description",
      subheadings: [
        "Seniority of First Nations people",
        "Board engagement",
        "First Nations advisory bodies",
        "Executive championship",
      ],
    },
    {
      icon: <GrGroup />,
      title: "Employment, engagement & pay equity",
      description: "description",
      subheadings: [
        "Recruitment & development program",
        "Pay gap audits & mitigation",
        "Leave policies",
        "Employee engagement",
      ],
    },
    {
      icon: <FaAward />,
      title: "Inclusive, culturally respectful workplace",
      description: "description",
      subheadings: [
        "Commitment to Reconciliation",
        "Cultural awareness, acknowledgement and recognition",
        "Employee resource groups",
        "Governance of First Nations Initiatives",
      ],
    },
    {
      icon: <BsPieChart />,
      title: "Racially just business practices",
      description: "description",
      subheadings: [
        "Supplier diversity program",
        "Stakeholder & community engagement",
        "Bespoke practices, products & services",
        "Culturally inclusive marketing practices",
      ],
    },
    {
      icon: <AiFillCustomerService />,
      title: "Ecosystem contributions",
      description: "description",
      subheadings: [
        "Self-determination, local and global sustainability",
        "First Nations' advocacy and justice initiatives",
        "High impact First Nations partnerships",
        "Philanthropic and commercial investments",
      ],
    },
  ],
};
