export const holdingPageData = {
  id: "holdingPage",
  heading: "Generating positive\nreal-world outcomes",
  paragraph:
    "By sharing our First Nations knowledge and experience, we help corporate Australia drive positive social change and promote reconciliation.",
  textColor: "#FFFFFF",
  backgroundImage: false,
  backgroundImageFileSmall: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileMedium: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileLarge: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileXLarge: "Dark_Blue_Graphic_BG.png",
  backgroundTinting: false,
  backgroundFilter: "none",
  alt: "First Nations Equity Partners' logo",
  backgroundColor: "#283039",
  backgroundLogo: "FNEP_Icon_Lavender.png",
  backgroundLogoActive: true,
};
