import styled from "styled-components";
import { device } from "../../device";

export const StandardTextContainer = styled.div`
  display: flex;
  height: auto;
  position: relative;
  z-index: 2;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
      `;
      default:
        return `
      flex-direction: column;
      justify-content: center;
      align-items: center;  
      padding: 50px 0;
      background: ${backgroundColor};
      `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  z-index: -1;
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  filter: ${(props) => props.logoBrightness};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  height: auto;
  max-height: calc(100% - 3rem);
  margin: auto;
  align-items: centre;

  @media ${device.laptopL} {
    max-height: calc(80% - 3rem);
  }
`;

export const HeadingWrapper = styled.div`
  margin: auto;
  width: 90%;

  @media ${device.tablet} {
    padding-bottom: 0.5rem;
  }

  @media ${device.laptop} {
    padding-bottom: 1rem;
  }

  @media ${device.laptopL} {
    padding-bottom: 2rem;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const SubheadingWrapper = styled.div`
  margin: auto;
  width: 90%;
`;

export const Subheading = styled.h2`
  text-align: center;
  margin-bottom: 24px;
  font-size: 1.25rem;
  line-height: 1.1;
  font-weight: 600;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 1.75rem;
  }
`;

export const TextWrapper = styled.div`
  margin: auto;
  width: 80%;
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  color: ${(props) => props.textColor};

  @media ${device.tablet} {
    font-size: 1.25rem;
  }
`;
