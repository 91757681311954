import styled from "styled-components";
import { device } from "../../device";

export const StatsContainer = styled.div`
  height: auto;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
          background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        `;
      default:
        return `
          background: ${backgroundColor};
        `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  z-index: -1;
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  filter: ${(props) => props.logoBrightness};
`;

export const StatsWrapper = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const HeadingWrapper = styled.div`
  width: 80%;
  margin: 0 auto 2rem;
  height: 10%;

  @media ${device.laptop} {
    margin: 0 auto;
  }

  @media ${device.laptopM} {
    margin: auto;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  color: ${(props) => props.textColor};
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  margin: auto;

  @media ${device.mobileL} {
    font-size: 3rem;
  }
`;

export const StatsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  width: 80%;
  max-width: 1350px;
  margin: auto;
  max-height: 80%;
  padding-top: 2rem;
  row-gap: 2rem;

  @media ${device.mobileM} {
    width: 90%;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 0;
    row-gap: 2rem;
  }
`;

export const StatRowContainer = styled.div`
  display: flex;
`;

export const StatRowWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-content: center;
  width: 95%;
  max-width: 600px;
  margin: auto;
  height: auto;

  @media ${device.mobileM} {
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
    min-height: 100px;
  }
`;

export const FigureContainer = styled.div`
  height: auto;
  display: flex;

  @media ${device.mobileM} {
    justify-items: end;
    padding-right: 1rem;
    min-height: 100px;
  }
`;

export const Figure = styled.h2`
  color: ${(props) => props.textColor};
  text-align: center;
  font-size: 1.8rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;

  @media ${device.mobileM} {
    text-align: right;
    margin: auto 0 auto auto;
    width: 100%;
  }

  @media ${device.mobileL} {
    font-size: 2.5rem;
  }

  @media ${device.laptopM} {
    font-size: 3rem;
  }
`;

export const StatContainer = styled.div`
  border-top: 5px solid #283039;
  height: auto;
  align-content: center;
  display: flex;

  @media ${device.mobileM} {
    border-top: none;
    border-left: 5px solid #283039;
    padding-left: 1rem;
  }
`;

export const Stat = styled.p`
  color: ${(props) => props.textColor};
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.4rem;
  margin-top: 0.8rem;
  margin-bottom: auto;

  @media ${device.mobileM} {
    text-align: left;
    margin-top: auto;
  }
`;

export const DisclaimerWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto 0;
  height: 10%;

  @media ${device.laptop} {
    margin: 0 auto;
  }

  @media ${device.laptopM} {
    margin: auto;
  }
`;

export const Disclaimer = styled.p`
  color: ${(props) => props.textColor};
  font-size: 1rem;

  @media ${device.mobileL} {
    margin-left: auto;
    margin-right: auto;
  }
`;
