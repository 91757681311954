import styled from "styled-components";
import { device } from "../../device";
import { FaTimes } from "react-icons/fa";

export const DisclaimerContainer = styled.div`
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  display: flex;
  background: #ffffff;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 100;
  position: fixed;
  align-content: center;
  justify-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: ${({ unmount }) => (unmount ? "slideDown" : "slideUp")};
  animation-duration: 0.5s;
  animation-timing-function: ease;
`;

export const StyledTimes = styled(FaTimes)`
  position: absolute;
  color: #283039;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  z-index: -1;
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;

export const DisclaimerWrapper = styled.div`
  height: 85%;
  width: 80%;
  max-width: 1350px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media ${device.laptop} {
    height: 80%;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  height: 5%;

  @media ${device.laptop} {
    height: 20%;
  }
`;

export const Heading = styled.h1`
  font-size: 1.5rem;
  line-height: 1.1;
  color: #283039;
  margin-top: auto;
  margin-bottom: 0;
  padding: 0 20px;

  @media ${device.mobileM} {
    font-size: 1.75rem;
  }

  @media ${device.mobileL} {
    font-size: 2rem;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 95%;

  @media ${device.laptop} {
    height: 80%;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.1rem;
  color: #283039;
  padding: 0 20px;
  margin-top: 0.5rem;

  @media ${device.mobileM} {
    font-size: 1.1rem;
    line-height: 1.25rem;
  }

  @media ${device.mobileL} {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;
