import styled from "styled-components";
import { device } from "../../device";

export const InfoContainer = styled.div`
  height: auto;
  padding: 50px 0;
  position: relative;
  z-index: 2;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
          background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        `;
      default:
        return `
          background: ${backgroundColor};
        `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    height: calc(100vh - 80px);
    padding: 0;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  z-index: -1;
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  filter: ${(props) => props.logoBrightness};
`;
// position: absolute;
// top: 500px;
// transform: translateY(200%);

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 3;
  height: 100%;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;

  @media ${device.tablet} {
    padding: 0 24px;
    max-width: 1350px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1%;
  align-items: center;
  grid-template-areas: "col1 col1" "col2 col2";
  align-content: center;

  @media ${device.tablet} {
    grid-template-areas: "col1 col2";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  grid-area: col1;

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }

  @media ${device.tablet} {
    width: 90%;
    padding: 0 15px;
    text-align: left;
    margin-bottom: 0;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  grid-area: col2;

  @media ${device.tablet} {
    padding: 0 15px 0 15px;
    text-align: left;
    margin-bottom: 0;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;

  @media ${device.tablet} {
    width: 90%;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ParagraphsWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  @media ${device.tablet} {
    width: 90%;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  align-content: center;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 3rem;
    margin: auto;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 35px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.textColor};

  @media ${device.tablet} {
    font-size: 1.25rem;
  }
`;
